<template>
  <div class="header">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="话术ID">
        <el-input
          v-model="form.scriptId"
          size="small"
          style="width: 200px"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="话术内容">
        <el-input
          v-model="form.scriptContent"
          size="small"
          style="width: 200px"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="最后修改时间">
        <el-date-picker
          v-model="form.time"
          size="small"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 340px"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="onSubmit"
          >查询</el-button
        >
        <el-button type="primary" size="small" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "PageHeader",
  components: {},
  data() {
    return {
      form: {},
      fold: false,
    };
  },
  methods: {
    onSubmit() {
      const data = { ...this.form };
      if (data.time) {
        data.updateTimeStart = moment(data.time[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        data.updateTimeEnd = moment(data.time[1]).format("YYYY-MM-DD HH:mm:ss");
      }

      delete data.time;
      this.$emit("search", data);
    },
    reset() {
      this.form = {};
      this.$emit("search", this.form);
    },

    checkFold() {
      this.fold = !this.fold;
    },
  },
};
</script>
<style lang="less" scoped>
</style>
