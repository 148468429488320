<template>
  <el-table ref="multipleTable" v-loading="loading" :data="tableData" border style="width: 100%">
    <el-table-column prop="date" label="序号" min-width="50">
      <template slot-scope="scope">
        <span>{{ scope.$index + 1 + (form.pageNumber - 1) * form.pageSize }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="scriptId" label="话术ID" min-width="80"> </el-table-column>
    <el-table-column prop="twoName" label="二级场景名称" min-width="180"> </el-table-column>
    <el-table-column prop="threeName" label="三级场景名称" min-width="180"> </el-table-column>
    <el-table-column :show-overflow-tooltip="true" prop="scriptContent" label="话术内容" min-width="250"> </el-table-column>
    <el-table-column prop="updateTime" label="最后修改时间" min-width="160"> </el-table-column>
    <el-table-column min-width="120" prop="address" label="操作">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
        <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectData: [],
    }
  },
  methods: {
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v)
      })
    },
    toggleSelection(rows) {
      if (rows) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
        // if (this.selectData.length > 0) {
        //   console.log(this.getArrDifference(this.tableData, this.selectData));
        //   this.getArrDifference(this.tableData, this.selectData).forEach(
        //     (row) => {
        //       this.$refs.multipleTable.toggleRowSelection(row, true);
        //     }
        //   );
        //   this.selectData.forEach((row) => {
        //     this.$refs.multipleTable.toggleRowSelection(row, false);
        //   });
        // }
      }
    },
    edit(row) {
      this.$emit('edit', row)
    },
    del(row) {
      this.$emit('del', row)
    },
    handleSelectionChange(val) {
      this.selectData = val
      this.$emit('handleSelectionChange', val)
    },
  },
}
</script>

<style lang="less" scoped></style>
