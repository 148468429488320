<template>
  <div class="wrap treeWrap">
    <div class="left">
      <div class="title">话术场景</div>
      <el-tree
        ref="tree"
        v-loading="treeLoading"
        :data="data"
        :props="defaultProps"
        node-key="categoryId"
        :default-expand-all="true"
        :highlight-current="true"
        :expand-on-click-node="false"
        @node-click="treeClick"
      >
        <span slot-scope="scope" class="custom-tree-node">
          <div class="label">
            <span class="name">{{ scope.data.categoryName }}</span>
            <div v-show="scope.data.categoryId == show.categoryId">
              <el-dropdown trigger="click" @command="handleCommand">
                <i class="el-icon-s-tools"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">新增同级场景</el-dropdown-item>
                  <el-dropdown-item v-if="scope.data.categoryType != 3" command="2">新增下级场景</el-dropdown-item>
                  <el-dropdown-item command="3">编辑场景</el-dropdown-item>
                  <el-dropdown-item command="4">删除场景</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </span>
      </el-tree>
    </div>
    <div class="right">
      <page-header @search="search" />
      <div class="table">
        <div class="btn">
          <el-button icon="el-icon-plus" type="primary" size="small" @click="add">新增</el-button>
        </div>
        <Table ref="table" :table-data="tableData" :form="form" :total="total" :loading="loading" @handleSelectionChange="handleSelectionChange" @edit="edit" @del="del" />
        <Pages :current-page="form.pageNumber" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" />
      </div>
    </div>
    <el-dialog :title="isAdd ? '新增话术' : '编辑话术'" :visible.sync="dialogFormVisible" :before-close="cancel" width="500px">
      <el-form ref="ruleForm" v-loading="speechLoading" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm" size="small">
        <el-form-item label="一级场景名称" prop="one">
          <el-select v-model="ruleForm.one" placeholder="请选择一级场景" @change="oneChange">
            <el-option v-for="(n, i) in oneCate" :key="i" :label="n.categoryName" :value="n.categoryId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二级场景名称" prop="two">
          <el-select v-model="ruleForm.two" placeholder="请选择二级场景" @change="twoChange">
            <el-option v-for="(n, i) in twoCate" :key="i" :label="n.categoryName" :value="n.categoryId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="三级场景名称" prop="categoryId">
          <el-select v-model="ruleForm.categoryId" placeholder="请选择三级场景">
            <el-option v-for="(n, i) in threeCate" :key="i" :label="n.categoryName" :value="n.categoryId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="话术内容" prop="scriptContent">
          <el-input v-model="ruleForm.scriptContent" style="width: 300px" type="textarea" :rows="8" maxlength="500" show-word-limit placeholder="请输入话术内容"> </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button :loading="btnLoading" size="small" type="primary" @click="ok">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="type != 3 ? '新增场景' : '编辑场景'" :visible.sync="scenVisible" width="500px" :before-close="sceneCancel">
      <el-form ref="sceneForm" :model="sceneForm" :rules="sceneRules" label-width="120px" class="demo-ruleForm" size="small">
        <el-form-item label="场景名称" prop="categoryName">
          <el-input v-model="sceneForm.categoryName" size="small" style="width: 260px" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="排序数字">
          <el-input v-model="sceneForm.sortNum" size="small" style="width: 260px" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="sceneCancel">取 消</el-button>
        <el-button :loading="sceneLoading" size="small" type="primary" @click="sceneOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageHeader from './header'
import Table from './table'
import Pages from '@/components/pages'
import { saleScriptGetList, saleGetList, saleSave, saleDelete, selectByExample, saleScriptDelete, saleScriptSave } from '@/api'
export default {
  components: {
    pageHeader,
    Table,
    Pages,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      multipleSelection: [],
      data: [],
      defaultProps: {
        children: 'childrens',
        label: 'categoryName',
      },
      scenVisible: false,
      speechLoading: false,
      isAdd: false,
      ruleForm: {
        one: '',
        two: '',
        categoryId: '',
        scriptContent: '',
      },
      sceneForm: {},
      dialogFormVisible: false,
      rules: {
        one: [{ required: true, message: '请选择一级场景', trigger: 'change' }],
        two: [{ required: true, message: '请选择二级场景', trigger: 'change' }],
        categoryId: [{ required: true, message: '请选择三级场景', trigger: 'change' }],
        scriptContent: [{ required: true, message: '请输入话术内容', trigger: 'change' }],
      },
      sceneRules: {
        categoryName: [{ required: true, message: '请输入场景名称', trigger: 'change' }],
      },
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      loading: false,
      show: {},
      type: '',
      sceneLoading: false,
      oneCate: [],
      twoCate: [],
      threeCate: [],
      btnLoading: false,
      treeLoading: false,
    }
  },
  mounted() {
    this.getTableData()
    this.saleGetList()
  },
  methods: {
    handleCommand(command, data) {
      if (command === 4) {
        this.$confirm('确认删除该条数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            saleDelete({
              data: {
                categoryId: this.show.categoryId,
              },
            }).then((res) => {
              this.saleGetList()
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            })
          })
          .catch(() => {})
      } else {
        this.type = command
        this.scenVisible = true
        if (command === 3) {
          this.$set(this.sceneForm, 'categoryName', this.show.categoryName)
          this.$set(this.sceneForm, 'sortNum', this.show.sortNum)
        }
      }
    },
    sceneOk() {
      this.$refs.sceneForm.validate((valid) => {
        if (valid) {
          this.sceneLoading = true
          if (this.type === 1) {
            this.sceneForm.parentId = this.show.parentId
            this.sceneForm.categoryType = this.show.categoryType
          }
          if (this.type === 2) {
            this.sceneForm.parentId = this.show.categoryId
            this.sceneForm.categoryType = this.show.categoryType + 1
          }
          if (this.type === 3) {
            this.sceneForm.parentId = this.show.parentId
            this.sceneForm.categoryId = this.show.categoryId
            this.sceneForm.categoryType = this.show.categoryType
          }
          saleSave({ data: this.sceneForm })
            .then((res) => {
              this.sceneCancel()
              this.sceneLoading = false
              this.show = {}
              this.saleGetList()
              this.$message({
                message: '保存成功',
                type: 'success',
              })
            })
            .catch(() => {
              this.sceneLoading = false
            })
        } else {
          return false
        }
      })
    },
    sceneCancel() {
      this.sceneForm = {}
      this.$refs.sceneForm.resetFields()
      this.scenVisible = false
    },
    saleGetList() {
      this.treeLoading = true
      saleGetList({ data: {} }).then((res) => {
        this.treeLoading = false
        this.data = res.data
      })
    },
    search(data) {
      this.form.pageNumber = 1
      this.form.data = { ...data }
      if (JSON.stringify(data) === '{}') {
        this.$refs.tree.setCurrentKey(null)
        this.show = {}
      }
      this.getTableData()
    },
    getTableData() {
      this.loading = true
      saleScriptGetList(this.form).then((res) => {
        this.loading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })
    },

    edit(row) {
      this.isAdd = false
      this.dialogFormVisible = true
      // this.ruleForm.scriptId = row.scriptId;
      // this.ruleForm.scriptContent = row.scriptContent;
      this.$nextTick(() => {
        this.$set(this.ruleForm, 'scriptId', row.scriptId)
        this.$set(this.ruleForm, 'scriptContent', row.scriptContent)
        selectByExample({
          data: {
            parentId: 0,
          },
        }).then((res) => {
          this.speechLoading = false
          this.oneCate = res.data
          this.ruleForm.one = row.oneCategoryId
        })
        selectByExample({
          data: {
            parentId: row.oneCategoryId,
          },
        }).then((res) => {
          this.speechLoading = false
          this.twoCate = res.data
          this.ruleForm.two = row.twoCategoryId
        })
        selectByExample({
          data: {
            parentId: row.twoCategoryId,
          },
        }).then((res) => {
          this.speechLoading = false
          this.threeCate = res.data
          this.$set(this.ruleForm, 'categoryId', row.categoryId)
        })
      })
    },
    del(row) {
      this.$confirm('确认删除该条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          saleScriptDelete({
            data: {
              scriptId: row.scriptId,
            },
          }).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.form.pageNumber = 1
            this.getTableData()
          })
        })
        .catch(() => {})
    },
    treeClick(data, node, e) {
      this.show = data
      this.form.data.categoryId = data.categoryId
      this.form.data.categoryType = data.categoryType
      this.getTableData()
      if (node.data.categoryType === 3) {
        this.show.parent = node.parent.data.parentId
      }
    },
    add() {
      this.isAdd = true
      this.dialogFormVisible = true
      this.speechLoading = true
      this.$nextTick(() => {
        if (JSON.stringify(this.show) !== '{}') {
          this.ruleForm.one = this.show.categoryType === 3 ? this.show.parent : this.show.categoryType === 1 ? this.show.categoryId : this.show.parentId
          if (this.show.categoryType === 2) {
            selectByExample({
              data: {
                parentId: this.show.categoryId,
              },
            }).then((res) => {
              this.threeCate = res.data
            })
            selectByExample({
              data: {
                parentId: this.show.parentId,
              },
            }).then((res) => {
              this.twoCate = res.data
              this.ruleForm.two = this.show.categoryId
            })
          }
          if (this.show.categoryType === 3) {
            selectByExample({
              data: {
                parentId: this.show.parent,
              },
            }).then((res) => {
              this.twoCate = res.data
              this.ruleForm.two = this.show.parentId
            })
            selectByExample({
              data: {
                parentId: this.show.parentId,
              },
            }).then((res) => {
              this.threeCate = res.data
              this.ruleForm.categoryId = this.show.categoryId
            })
          }
          if (this.show.categoryType === 1) {
            selectByExample({
              data: {
                parentId: this.show.categoryId,
              },
            }).then((res) => {
              this.twoCate = res.data
            })
          }
        }
        selectByExample({
          data: {
            parentId: 0,
          },
        }).then((res) => {
          this.speechLoading = false
          this.oneCate = res.data
        })
      })
    },
    oneChange(e) {
      console.log(e)
      this.speechLoading = true
      selectByExample({
        data: {
          parentId: e,
        },
      }).then((res) => {
        this.speechLoading = false
        this.twoCate = res.data
        this.threeCate = []
        this.$set(this.ruleForm, 'two', '')
        this.$set(this.ruleForm, 'categoryId', '')
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate()
        })
      })
    },
    twoChange(e) {
      this.speechLoading = true
      selectByExample({
        data: {
          parentId: e,
        },
      }).then((res) => {
        this.speechLoading = false
        this.threeCate = res.data
        this.$set(this.ruleForm, 'categoryId', '')
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate()
        })
      })
    },
    ok() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = {}
          data.categoryId = this.ruleForm.categoryId
          data.scriptContent = this.ruleForm.scriptContent
          if (this.ruleForm.scriptId) {
            data.scriptId = this.ruleForm.scriptId
          }
          this.btnLoading = true
          saleScriptSave({ data: { ...data } })
            .then((res) => {
              this.btnLoading = false
              this.cancel()
              this.form.pageNumber = 1
              this.getTableData()
              this.$message({
                message: '保存成功',
                type: 'success',
              })
            })
            .catch(() => {
              this.btnLoading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    cancel() {
      this.$refs.ruleForm.resetFields()
      this.oneCate = []
      this.twoCate = []
      this.threeCate = []
      this.dialogFormVisible = false
    },
    toggleSelection(rows) {
      this.$refs.table.toggleSelection(rows)
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    handleSelectionChange(val) {
      console.log('当前选择', val)
      this.multipleSelection = val
    },
    exportTable() {},
  },
}
</script>
<style lang="less" scoped></style>
